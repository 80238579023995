import React from "react";
import {
  Button,
  ButtonWrapper,
  Container,
  Label,
  ProgressDots,
  ErrorText,
} from "./MeetTimeSelect.css";
import { AppScreen } from "../../../stackflow/components";
import { useFlow } from "../../../stackflow";
import useActiveActivities from "../../../stackflow/hooks/useActiveActivities";
import TimePicker from "../components/TimePicker";
import { Spacing } from "@components/common/Spacing";
import { TimeOptionChips } from "../components/TimePicker/TimeOptionChips";
import { useQueryParams } from "../../../stackflow/hooks/useQueryParams";
import usePostNewMeet from "@/apis/hooks/usePostNewMeet";
import { enqueueSnackbar } from "notistack";
import OverlaySpinnerView from "@components/common/Spinner/OverlaySpinnerView";

const MeetTimeSelect: React.FC = () => {
  const { pop, push } = useFlow();
  const [startTime, setStartTime] = React.useState<number>(0);
  const [endTime, setEndTime] = React.useState<number>(0);
  const { diffActivitiesFromCurrent } = useActiveActivities();
  const { title = "", startDate = "", endDate = "" } = useQueryParams();

  const { mutate, isPending } = usePostNewMeet({
    onError: () => {
      console.log("error");
      enqueueSnackbar("모임 생성에 실패했어요. 잠시후 다시 시도해주세요.", {
        variant: "default",
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        preventDuplicate: true,
      });
    },
    onSuccess: (data) => {
      const meetId = data?.data?.meet?._id;

      const homePageDiff = diffActivitiesFromCurrent("HomePage");
      if (homePageDiff !== -1) {
        pop(homePageDiff);
      }

      push("CreateResultPage", {
        meetId,
        title,
        startDate,
        endDate,
        startTime,
        endTime,
      });
    },
  });

  const handleSubmit = async () => {
    if (!enableButton) return;
    mutate({
      title,
      startDate,
      endDate,
      startTime,
      endTime,
    });
  };

  const handelTimeChip = (startTime: number, endTime: number) => {
    setStartTime(startTime);
    setEndTime(endTime);
  };

  const enableButton = startTime < endTime;

  return (
    <AppScreen
      appBar={{
        border: false,
      }}
      accessoryBar={
        <ButtonWrapper>
          <ErrorText>
            {startTime >= endTime
              ? "시작 시간보다 종료 시간이 늦을 수 없어요"
              : ""}
          </ErrorText>
          <Button enable={enableButton} onClick={handleSubmit}>
            만들기
          </Button>
        </ButtonWrapper>
      }
    >
      {isPending && <OverlaySpinnerView />}
      <Container>
        <ProgressDots>
          <span className="active"></span>
          <span className="active"></span>
          <span className="active"></span>
        </ProgressDots>
        <Label>시간 선택</Label>
        <Spacing height={40} />
        <TimePicker
          setStartTime={setStartTime}
          setEndTime={setEndTime}
          startTime={startTime}
          endTime={endTime}
        />
        <Spacing height={50} />
        <TimeOptionChips onClickTimeChip={handelTimeChip} />
      </Container>
    </AppScreen>
  );
};

export default MeetTimeSelect;
